<template>
	<div>
		<v-dialog v-model="dialog_master" width="640" height="720" centered persistent>
			<v-card width="640" height="720" >
				<v-card-title>{{의료기관명}} Staff관리</v-card-title>
				<v-card-subtitle></v-card-subtitle>
				<v-card-text>
					<v-btn @click="type='new';edit_user()" class="mb-2"><v-icon class="me-1" >mdi-account-plus</v-icon> new staff</v-btn>
					<v-table
						class="mytable"
						fixed-header
					>
						<thead>
							<tr bgcolor="#ccc" style="border-bottom:1px solid #aaa;">
								<td class="text-center">아이디</td>
								<td class="text-center">성명</td>
								<td class="text-center">부서</td>
								<td class="text-center">구분</td>
								<td class="text-center">면허번호</td>
								<td class="text-center">비번</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="staff in staffs" class="mytr" @click="edit_user(staff)" style="border-top:1px solid #aaa;" :style="{'opacity':!staff.state?'.5':'1'}">
								<td class="text-center">{{staff.user_id}}</td>
								<td class="text-center">
									{{staff.user_name}}
									<v-icon v-if="staff.state" @click="del_user(staff,$event)" color="error" size="x-small">mdi-close</v-icon>
									<v-icon v-else @click="del_user(staff,$event)" color="green" size="x-small">mdi-check</v-icon>
								</td>
								<td class="text-center">{{staff.user_position}}</td>
								<td class="text-center">{{staff.user_categ}}</td>
								<td class="text-center">{{staff.user_license}}</td>
								<td class="text-center"><v-icon @click="reset_passwd(staff,$event)" v-if="staff.user_pwd" size="x-small">mdi-auto-fix</v-icon></td>
							</tr>
						</tbody>
					</v-table>
				</v-card-text>
				<v-card-actions style="display:flex;justify-content:right;">
					<v-btn style="background-color:gray;color:#fff" @click="view_account()" >Master 계정정보</v-btn>
					<v-btn style="background-color:gray;color:#fff" @click="dialog_master=false" >닫기</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog_editor" max-width="500" max-height="400" centered persistent>
			<v-card max-width="500" height="400" >
				<v-card-title>
					Staff 
					<span v-if="type=='new'">추가</span>
					<span v-else>수정</span>
				</v-card-title>
				<v-card-text>
					<div class="d-flex align-center">
						<div>
							<v-text-field
								label="사용자ID"
								v-model="사용자id"
								density="compact" 
								hide-details 
								variant="outlined"
								class="mt-1 mb-2 text-body-1 custom red"
								style="width:150px;"
								:readonly="type=='modi'"
								@input="filterInput_id"
							></v-text-field>
						</div>
						<div>
							@{{ 마스터id }}
						</div>
					</div>
					<v-text-field
						label="성명"
						v-model="성명"
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					></v-text-field>
					<v-text-field
						label="부서"
						v-model="부서"
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					></v-text-field>
					<v-select
						label="구분"
						v-model="구분"
						:items="['한의사','직원']"
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					></v-select>
					<v-text-field
						label="면허번호"
						v-model="면허번호"
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					></v-text-field>
				</v-card-text>
				<v-card-actions style="display:flex;justify-content:right;">
					<v-btn style="background-color:gray;color:#fff" @click="save">저장</v-btn>
					<v-btn style="background-color:gray;color:#fff" @click="dialog_editor=false" >닫기</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>
//import axios from 'axios';
import DivKakao from './kakao'

export default {
	name: "App",
	components: {
		DivKakao
	},
	data: function () {
		return {
			dialog_master:false,
			dialog_editor:false,
			staffs:[],
			성명:'',
			부서:'',
			구분:null,
			면허번호:'',
			사용자id:'',
			의료기관명:'',
			type:'new',
			obj:{}
		};
	},
	watch:{
	
	},
	unmounted(){
		this.emitter.off('dialog_master_open')
	},
	mounted(){
		this.emitter.on('dialog_master_open',(obj)=>{
			this.obj = obj
			this.status = obj.status
			this.카카오id = obj?.kakaoid
			this.카카오계정 = obj?.email
			this.대표자성명 = obj?.ceo
			this.의료기관명 = obj?.medi_name
			this.전화번호 = obj?.medi_tel
			this.주소 = obj?.medi_juso
			this.전화번호 = obj?.medi_tel
			this.요양기관번호 = obj?.medi_num
			this.사업자번호 = obj?.medi_biz
			this.마스터id = obj?.medi_id
			this.dialog_master = true
			this.load_staff()
		})
	},
	methods: {
		filterInput_id(e){
			var input = e.target.value
			input = input.replace(/[^a-zA-Z0-9]/g, '');
			this.사용자id = input;
		},
		del_user(S,e){
			if(S.state){
				if(!confirm('사용자를 삭제하시겠습니까?')) return
			}else{
				if(!confirm('사용자를 복구하시겠습니까?')) return
			}
			e.stopPropagation()
			axios.post('/del_user',{
				user_id:S.user_id,
				state:S.state,
			}).then((rsp)=>{
				this.load_staff()
			})
		},
		reset_passwd(S,e){
			e.stopPropagation()
			if(!confirm('비밀번호를 초기화 하시겠습니까?')) return false
			axios.post('/reset_passwd',{
				user_id:S.user_id,
			}).then((rsp)=>{
				this.load_staff()
			})
		},
		edit_user(S){
			if(S){
				this.type = 'modi'
				this.성명 = S.user_name
				this.부서 = S.user_position
				this.구분 = S.user_categ
				this.면허번호 = S.user_license||''
				this.사용자id = S.user_id.replace('@'+this.마스터id,'')
			}else{
				this.type = 'new'
				this.성명 = ''
				this.부서 = ''
				this.구분 = null
				this.면허번호 = ''
				this.사용자id = ''
			}
			this.dialog_editor = true
		},
		save(){
			if(this.사용자id.length<3){
				alert('3자 이상')
				return false
			}
			if(!this.성명){alert('필수누락'); return false;}
			if(!this.부서){alert('필수누락'); return false;}
			if(!this.구분){alert('필수누락'); return false;}
			if(!this.마스터id){alert('필수누락'); return false;}

			axios.post('/save_subid',{
				성명:this.성명,
				부서:this.부서,
				구분:this.구분,
				면허번호:this.면허번호,
				카카오id:this.카카오id,
				마스터id:this.마스터id,
				사용자id:this.사용자id,
			}).then((rsp)=>{
				this.성명 = ''
				this.부서 = ''
				this.사용자id = ''
				this.load_staff()
				this.dialog_editor = false
			})
		},
		view_account(){
			this.emitter.emit('dialog_join_open',this.obj)
		},
		load_staff(){
			axios.post('/staff',{
				카카오id:this.카카오id,
			}).then((rsp)=>{
				this.staffs = rsp.data
			})
		}
	},
}
</script>

<style scoped>
.mytable{
	width:100%;
	border-collapse: collapse;
	border-spacing: 0;
	border:0px solid #aaa;
	border-top:1px solid #aaa;
	border-bottom:1px solid #a9a9a9;
}
.mytable td{
	padding:3px;
	font-size:14px;
	border:0px solid #aaa;
}
.mytable tbody{
	border-bottom:1px solid #a9a9a9;
}
.mytr:hover{
	background-color: yellow;
}
</style>
