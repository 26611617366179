<template>
	<div>
		<v-dialog v-model="dialog_join" width="400" height="600" centered persistent>
			<v-card width="400" height="600">
				<v-card-title>
					한의원등록 {{ status=='ok'?'정보':'' }}

				</v-card-title>
				<v-card-subtitle>
					<span v-if="status=='ok'"></span>
					<span v-else-if="status=='waiting'">등록 대기중입니다. 승인을 기다려주세요.</span>
					<span v-else>등록된 한의원이 없습니다. 한의원을 등록해주세요.</span>
				</v-card-subtitle>
				<v-card-text>
					<v-text-field 
						label="카카오계정" 
						v-model="카카오계정" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
						:readonly="true"
					>
					</v-text-field>
					<v-text-field 
						label="마스터ID" 
						v-model="마스터id" 
						density="compact" 
						variant="outlined"
						class="mt-1 text-body-1 custom red"
						hint="예) 마스터ID가 hani 이면 하위ID는 xxx@hani ..."
						persistent-hint
						@input="filterInput_id"
						:readonly="status=='ok'"
					>
					</v-text-field>
					<v-text-field 
						label="대표자 성명" 
						v-model="대표자성명" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					>
					</v-text-field>
					<v-text-field 
						label="의료기관명" 
						v-model="의료기관명" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					>
					</v-text-field>
					<v-text-field 
						label="주소" 
						v-model="주소" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
					>
					</v-text-field>
					<v-text-field 
						label="전화번호" 
						v-model="전화번호" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
						@input="filterInput_tel"
					>
					</v-text-field>
					<v-text-field 
						label="요양기관번호" 
						v-model="요양기관번호" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
						@input="filterInput_요양기관번호"
					>
					</v-text-field>
					<v-text-field 
						label="사업자번호" 
						v-model="사업자번호" 
						density="compact" 
						hide-details 
						variant="outlined"
						class="mt-1 mb-2 text-body-1 custom red"
						@input="filterInput_사업자번호"
					>
					</v-text-field>
				</v-card-text>
				<v-card-actions style="display:flex;justify-content:right;">
					<v-btn style="background-color:gray;color:#fff" @click="logout">로그아웃</v-btn>
					<v-btn v-if="status=='waiting'" style="background-color:red;color:#fff" @click="join()">수정</v-btn>
					<v-btn v-else style="background-color:#000;color:#fff" @click="join()">저장</v-btn>
					<v-btn style="background-color:gray;color:#fff" @click="dialog_join=false">닫기</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>
//import axios from 'axios';

export default {
	name: "App",
	components: {
		
	},
	data: function () {
		return {
			dialog_join:false,
			카카오계정:'',
			대표자성명:'',
			의료기관명:'',
			전화번호:'',
			주소:'',
			전화번호:'',
			요양기관번호:'',
			사업자번호:'',
			마스터id:'',
			status:'new',
			카카오id:'',
		};
	},
	watch:{
	
	},
	updated(){
		document.querySelectorAll(".custom .v-input__details").forEach( el=>{
			el.style.padding = '2px'
			el.style.paddingBottom = '5px'
			el.style.minHeight = '0px'
			el.style.marginBottom = '10px'
			el.style.color='red'
		})
	},
	unmounted(){
		this.emitter.off('dialog_join_open')
	},
	mounted(){
		this.emitter.on('dialog_join_open',(obj)=>{
			this.status = obj.status
			this.카카오id = obj?.kakaoid
			this.카카오계정 = obj?.email
			this.대표자성명 = obj?.ceo
			this.의료기관명 = obj?.medi_name
			this.전화번호 = obj?.medi_tel
			this.주소 = obj?.medi_juso
			this.전화번호 = obj?.medi_tel
			this.요양기관번호 = obj?.medi_num
			this.사업자번호 = obj?.medi_biz
			this.마스터id = obj?.medi_id
			this.dialog_join = true
		})
	},
	methods: {
		join(){
			if(!this.대표자성명){alert('필수누락');return false}
			if(!this.의료기관명){alert('필수누락');return false}
			if(!this.전화번호){alert('필수누락');return false}
			if(!this.주소){alert('필수누락');return false}
			if(!this.전화번호){alert('필수누락');return false}
			if(!this.요양기관번호){alert('필수누락');return false}
			if(!this.사업자번호){alert('필수누락');return false}
			if(!this.마스터id){alert('필수누락');return false}
			axios.post('/join',{
				카카오id:this.카카오id,
				카카오계정:this.카카오계정,
				대표자성명:this.대표자성명,
				의료기관명:this.의료기관명,
				전화번호:this.전화번호,
				주소:this.주소,
				전화번호:this.전화번호,
				요양기관번호:this.요양기관번호,
				사업자번호:this.사업자번호,
				마스터id:this.마스터id
			}).then((rsp)=>{
				if(rsp.data=='duplicated num'){
					alert('이미 등록된 요양기관번호입니다.')
				}
				if(rsp.data=='duplicated id'){
					alert('이미 등록된 마스터ID입니다.')
				}
				this.emitter.emit('dialog_join_reload')
				this.dialog_join = false
			})
		},
		filterInput_사업자번호(e){
			var input = e.target.value
			input = input.replace(/[^0-9]/g, '');
			if (input.length > 3 && input.length <= 5) {
				input = input.slice(0, 3) + '-' + input.slice(3);
			} else if (input.length > 5) {
				input = input.slice(0, 3) + '-' + input.slice(3, 5) + '-' + input.slice(5, 10);
			}
			this.사업자번호 = input;
		},
		filterInput_요양기관번호(e){
			var input = e.target.value
			input = input.replace(/[^0-9]/g, '');
			input = input.slice(0, 8);
			this.요양기관번호 = input;
		},
		filterInput_id(e){
			var input = e.target.value
			input = input.replace(/[^a-zA-Z0-9]/g, '');
			this.마스터id = input;
		},
		filterInput_tel(e){
			let input = e.target.value;
			input = input.replace(/[^0-9]/g, '');
			if (input.length > 11) {
				input = input.slice(0, 11);
			}
			if (input.length > 6) {
				input = input.slice(0, 3) + '-' + input.slice(3, input.length > 10 ? 7 : 6) + '-' + input.slice(input.length > 10 ? 7 : 6);
			} else if (input.length > 3) {
				input = input.slice(0, 3) + '-' + input.slice(3);
			}
			this.전화번호 = input;
		},
		logout(){
			var Q = confirm('로그아웃하시겠습니까?')
			if(Q==false){
				return false;
			}
			location.replace('/ask_logout')
		},
	},
}
</script>

<style scoped>

</style>
