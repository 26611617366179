import { createApp } from 'vue'
import mitt from 'mitt'
const emitter = mitt()

import App from './vue/login/root';
const app = createApp(App)

app.config.globalProperties.emitter = emitter

import { createVuetify } from 'vuetify'
import 'vuetify/dist/vuetify.css'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
	components,
	directives,
})

app.use(vuetify)
app.mount('#root')