<template>
	<div id="mainDiv2">

		
		<v-sheet width="auto" height="900">
			<v-card width="800" height="700" style="display:grid;grid-template-rows:70px 1fr 70px;" class="pa-0"  elevation="12">
				<v-card-title class="pa-0">
					<v-toolbar color="#06336B">
						<v-icon class="ms-5">mdi-bullhorn</v-icon>
						<v-toolbar-title>Notice</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-card-text style="overflow:auto;">
					<v-list
					:items="items"
					lines="three"
					item-props
					>
						<template v-slot:subtitle="{ subtitle }">
							<div v-html="subtitle"></div>
						</template>
					</v-list>
				</v-card-text>
				<v-card-actions class="d-flex justify-end">
					<v-btn style="background-color:#3F51B5;color:#fff;" @click="entry" v-if="!dialog" size="large">입장</v-btn>
				</v-card-actions>
			</v-card>
		</v-sheet>
				

				<!-- <v-card class="mx-auto" max-width="400" min-height="900">
					<v-toolbar color="cyan-lighten-1">
						<v-icon class="ms-5">mdi-bullhorn</v-icon>
						<v-toolbar-title>Notice</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
					<v-card-title>
						test
					</v-card-title>
					<v-card-text>
						tsdfsd
						<v-list
						:items="items"
						lines="three"
						item-props
						>
							<template v-slot:subtitle="{ subtitle }">
								<div v-html="subtitle"></div>
							</template>
						</v-list>
					</v-card-text>
					<v-card-actions >
						<v-btn style="background-color:#3F51B5;color:#fff;" @click="entry">입장</v-btn>
					</v-card-actions>
				</v-card> -->

		<v-dialog v-model="dialog" max-width="500" height="600" centered persistent>
			<v-card max-width="500" height="360" color="indigo">
				<v-card-title>
					<h3 style="font-family:'Zen Dots';font-weight:normal;">Dr. Dev</h3>
				</v-card-title>
				<v-card-text class="px-5">
					<br>
					<v-text-field
						label="ID"
						v-model="userId"
					></v-text-field>
					<v-text-field
						label="Password"
						type="password"
						v-model="pwd"
						@keydown.enter="login"
					></v-text-field>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between" height="40">
					<div>
						<v-checkbox label="Remember ID" hide-details v-model="remember"></v-checkbox>
					</div>
					<div>
						<v-btn style="background-color:gray;color:#fff" size="large" @click="kakao">join</v-btn>
						<v-btn style="background-color:gray;color:#fff" size="large" @click="login">Login</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog_setting" max-width="500" max-height="400" centered >
			<v-card max-width="500" height="360">
				<v-card-title>
					비밀번호 세팅
				</v-card-title>
				<v-card-subtitle>
					{{userId}}의 비밀번호가 초기화되었습니다. 비밀번호를 설정해주세요.
				</v-card-subtitle>
				<v-card-text class="px-5">
					<br>
					<v-text-field
						label="Password"
						type="password"
						v-model="pwd1"
						hide-details 
					></v-text-field>
					<v-text-field
						label="confirm"
						type="password"
						v-model="pwd2"
						hide-details 
					></v-text-field>
					<span v-if="pwd1&&pwd1.length<6" style="font-size:12px;color:red">too short!</span>
					<span v-else-if="pwd2&&pwd2!==pwd1" style="font-size:12px;color:red">no matched!</span>
					
				</v-card-text>
				<v-card-actions style="display:flex;justify-content:right;">
					<v-btn style="background-color:gray;color:#fff" size="large" @click="save">save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<div-kakao></div-kakao>
		<div-users></div-users>
	</div>
</template>

<script>
Kakao.init('9e4518ca72fd308523016fe884cb2245');
import DivUsers from './users'
import DivKakao from './kakao'

//import axios from 'axios';
export default {
	name: "App",
	components: {
		DivUsers,DivKakao
	},
	data: function () {
		return {
			dialog:userId?false:true,
			userId:localStorage.getItem('savedUsername'),
			pwd:'',
			pwd1:'',
			pwd2:'',
			dialog_join:false,
			카카오계정:'',
			대표자성명:'',
			의료기관명:'',
			전화번호:'',
			주소:'',
			전화번호:'',
			요양기관번호:'',
			사업자번호:'',
			마스터id:'',
			status:'new',
			카카오id:'',
			dialog_master:false,
			dialog_editor:false,
			dialog_setting:false,
			remember:!!localStorage.getItem('savedUsername'),
			res:{},
			items:[
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
					title: 'Brunch this weekend?',
					subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
					title: 'Summer BBQ',
					subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
					title: 'Oui oui',
					subtitle: '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
					title: 'Birthday gift',
					subtitle: '<span class="text-primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
					title: 'Recipe to try',
					subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
				},
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
					title: 'Brunch this weekend?',
					subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
					title: 'Summer BBQ',
					subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
					title: 'Oui oui',
					subtitle: '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
					title: 'Birthday gift',
					subtitle: '<span class="text-primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
				},
				{ type: 'divider', inset: true },
				{
					prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
					title: 'Recipe to try',
					subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
				},
			]
		};
	},
	updated(){
		
	},
	unmounted(){
		this.emitter.off('dialog_info_open')
	},
	mounted(){
		if (!window.locationbar.visible && !window.menubar.visible) {
			window.close()
		}

		document.documentElement.style.overflow = 'hidden';

		this.emitter.on('dialog_info_open',()=>{
			this.dialog_join = true
		})

		this.emitter.on('dialog_join_reload',()=>{
			axios
			.post('/kakaoLogin',{msg:this.res})
			.then((response)=>{
				if(response.data.status == 'ok'){
					this.emitter.emit('dialog_master_open',response.data)
				}
			})
		})
	},
	methods: {
		entry(){
			// var width = screen.width;
            // var height = screen.height;
            // var left = 0;
            // var top = 0;
            // var features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no`;
            // window.open("/", "_blank", features);
			location.replace('/')
		},
		save(){
			if(this.pwd1!==this.pwd2){
				return false
			}
			if(this.pwd1.length<8){
				return false
			}
			axios.post('/set_pwd',{
				userId:this.userId,
				pwd1:this.pwd1,
				pwd2:this.pwd2
			}).then((rsp)=>{
				this.dialog_setting = false
			})
		},
		
		login(){
			axios.post('/auth',{
				userId:this.userId,
				pwd:this.pwd,
			}).then((rsp)=>{
				if(rsp.data=='비밀번호 설정이 필요합니다.'){
					this.dialog_setting = true
				}else if(rsp.data=='성공'){
					if(this.remember){
						localStorage.setItem('savedUsername',this.userId);
					}else{
						localStorage.setItem('savedUsername','');
					}
					this.dialog = false
				}else{
					alert('일치하는 사용자가 없습니다.')
				}
			})
		},
		kakao() {
			Kakao.Auth.login({
				success: ()=>{
					Kakao.API.request({
						url: '/v2/user/me',
						success:(res)=>{
							this.res = res
							axios
							.post('/kakaoLogin',{msg:res})
							.then((response)=>{
								if(response.data.status == 'ok'){
									this.emitter.emit('dialog_master_open',response.data)
								}else{
									this.emitter.emit('dialog_join_open',response.data)
								}
							})
						;}
					});
				},
				fail: function(err) {
					alert(JSON.stringify(err));
				}
			});
		}
	},
	
}
</script>

<style scoped>
#mainDiv2{
	position:absolute;
	left:0px;top:0px;bottom:0px;right:0px;
	display:grid;
	grid-template-columns:1fr;
	grid-template-rows:1fr;
	align-items:center;
	justify-items:center;
}
</style>
